import ComparisonTable from 'components/ComparisonTable';
import React, { useContext } from 'react';
import { ProductInfoContext } from 'utils/helpers/pdpContexts';

const ComparisonTableContainer = () => {
  const { comparisonTable, slug } = useContext(ProductInfoContext);

  if (!comparisonTable) {
    return null;
  }

  return <ComparisonTable {...comparisonTable} slug={slug} />;
};

export default ComparisonTableContainer;
